<template>
  <QRReader
      v-model:show="show"
      @onRead="onRead"
  />
    <van-cell-group title="绑定发货订单" inset>
      <van-cell>
      <van-field
                v-model="code"
                name="code"
                label="发货单"
                placeholder="请扫描发货单"
                required
                :rules="[{ required: true, message: '请扫描发货单' }]"
                @focus="this.focusModel= 'code'"
        ></van-field>
      <van-button round block type="primary" @click="this.show= true,lastShow='code'" style="height: 5.33333vw">
        扫描二维码
      </van-button>
      </van-cell>

        <van-field
                v-model="headCarCode"
                name="headCarCode"
                label="车牌号"
        ></van-field>
        <Picker
                name="loadCarOrderDetailId"
                label="客户订单"
                placeholder="请选择客户订单"
                displayField="outStockCode"
                valueField="loadCarOrderDetailId"
                :required=false
                v-model:value="this.$data['loadCarOrderDetailId']"
                :defConfig="{
                    portName:'fr.order.out.LoadCarOrder.scanLoadCarOrder',
                    data: {'code': this.code},
            }"
                :canSearch=true
                @onConfirm="onLoadCarOrderOutStockConfirm"
        />

      <van-cell>
        <van-field
                v-model="packageCode"
                name="packageCode"
                label="箱号"
                placeholder="请扫描物料包装"
                required
                :rules="[{ required: true, message: '请扫描物料包装' }]"
                @focus="this.focusModel= 'packageCode'"
        ></van-field>
      <van-button round block type="primary" @click="this.show= true,lastShow='packageCode'" style="height: 5.33333vw">
        扫描二维码
      </van-button>
      </van-cell>

      <!-- <div style="margin: 4.26667vw;">
           <van-button round block type="primary" @click="submitReceive()">
               提交
           </van-button>
       </div>-->

        <van-field
                v-model="info"
                name="info"
                label="信息"
        ></van-field>
        <div style="margin: 4.26667vw;">
            <van-button round block type="primary" @click="scanCheck()">
                核验
            </van-button>
        </div>
    </van-cell-group>

    <van-cell-group inset title="发货物料">
        <ListCard
                v-for="item in dataList"
                :key="item.id"
                :id="item.id"
        >
            <div class="item">
                <div class="fs12">
                    <div class="tags fs16">{{'物料名称：' + item['materialName']}}</div>
                    <div class="tags fs16">{{'物料编码：' + item['materialCode']}}</div>
                    <div class="tags fs16">{{'需要数量：' + item['deliveryQty']}}</div>
                    <div class="tags fs16">{{'已扫数量：' + item['deliveryQtyGenerated']}}</div>
                </div>
            </div>
        </ListCard>
    </van-cell-group>

</template>

<script>
    import Picker from "../../components/Picker";
    import ListCard from "../../components/ListCard";
    import {Toast} from "vant";
    import QRReader from "@/components/QRReader.vue";

    export default {
        name: "Loadcarorder",
        components: {Picker, ListCard,QRReader},
        data() {
            return {
                code: '',
              show:false,
              lastShow:"",
                headCarCode: '',
                loadCarOrderDetailId: '',
                outStockCode: '',
                receiptOrderID: '',
                loadCarOrderId: '',
                packageCode: '',
                focusModel: '',
                info: '',
                dataList: [],
                dataList1: [],
            }
        },
        created() {
          if (!this.$sapi.isLogin()) {
            this.$sapi.handleTokenError();
            return;
          }
            this.getScanCode();
        },
        methods: {

          onRead(text) {
            var me=this;
            if(this.lastShow ==='code') {
              me.code = text;
              var option1 = {
                portName: 'fr.order.out.LoadCarOrder.getLoadCarOrderByCode',
                data: {'code': me.code},
                needLoading: false,
                successCallback: function (data) {
                  if (data.entities.length > 0) {
                    me.headCarCode = data.entities[0].headCarCode;
                    me.loadCarOrderId = data.entities[0].id;
                  }
                }
              };
              this.$sapi.callPort(option1);
            }else if(this.lastShow ==='packageCode') {
              if (me.loadCarOrderDetailId === '') {
                Toast('请选择客户订单');
                return;
              }

              me.packageCode = text;
              var option = {
                portName: 'fr.order.out.LoadCarOrder.scanLoadCarOrderPackageCode',
                data: {'packageCode': me.packageCode, 'loadCarOrderDetailId': me.loadCarOrderDetailId},
                needLoading: false,
                successTone: true,
                failTone: true,
                successCallback: function (data) {
                  me.info = data.data.info;
                  me.dataList = data.data.materialDicList;
                }
              };
              this.$sapi.callPort(option);
              }
          },
            getScanCode() {
                if (!window.Capacitor) {
                    return;
                }
                var me = this;
                Capacitor.Plugins.Scanner.addListener('onScan', (data) => {
                    var barcode = data.barcode;
                    console.log(data.barcode);
                    switch (me.focusModel) {
                        case 'code' :
                            me.code = barcode;
                            var option1 = {
                                portName: 'fr.order.out.LoadCarOrder.getLoadCarOrderByCode',
                                data: {'code': me.code},
                                needLoading: false,
                                successCallback: function (data) {
                                    if (data.entities.length > 0) {
                                        me.headCarCode = data.entities[0].headCarCode;
                                        me.loadCarOrderId = data.entities[0].id;
                                    }
                                }
                            };
                            this.$sapi.callPort(option1);
                            break;
                        case 'packageCode' :
                            if (me.loadCarOrderDetailId === '') {
                                Toast('请选择客户订单');
                                return;
                            }

                            me.packageCode = barcode;
                            var option = {
                                portName: 'fr.order.out.LoadCarOrder.scanLoadCarOrderPackageCode',
                                data: {'packageCode': me.packageCode, 'loadCarOrderDetailId': me.loadCarOrderDetailId},
                                needLoading: false,
                                successTone: true,
                                failTone: true,
                                successCallback: function (data) {
                                    me.info = data.data.info;
                                    me.dataList = data.data.materialDicList;
                                }
                            };
                            this.$sapi.callPort(option);
                            break;
                    }
                })
            },
            diffDeal(value) {
                var me = this;
                var option = {
                    portName: 'fr.order.ReceiptOrderLevel.diffDeal',
                    data: {'receiptOrder.id': me.receiptOrderID, 'packCodeList': me.packCodeList},
                    needLoading: true,
                    successCallback: function (data) {
                        me.$refs.grid.$props.requestData['receiptOrder.id'] = me.receiptOrderID;
                        me.$refs.grid.onRefresh();
                    }
                };
                this.$sapi.callPort(option);
            },
            submitReceive1(value) {
                var me = this;
                var ids = [];
                ids.push(me.loadCarOrderId);
                var option = {
                    portName: 'fr.order.out.LoadCarOrder.passLoadCarOrders"',
                    data: {"ids":ids},
                    needLoading: true,
                    successCallback: function (data) {
                        me.$dialog.alert({
                            title: '',
                            message: '提交成功',
                        }).then(() => {
                        });
                    }
                };
                this.$sapi.callPort(option);
            },
            submitReceive(value) {
                var me = this;
                var data = [];
                data['id'] =me.loadCarOrderId;
                var option = {
                    portName: 'fr.order.out.LoadCarOrder.checkPassLoadCarOrder',
                    data: data,
                    needLoading: true,
                    successCallback: function (data) {
                        var checkPass = data.data.checkPass;
                        if (checkPass) {
                            me.submitReceive1();
                        } else {
                            var checkPassMsg = data.data.checkPassMsg+ '  是否继续提交？';
                            me.$dialog.confirm({
                                title: '',
                                message: checkPassMsg,
                            }).then(() => {
                                me.submitReceive1();
                            });
                        }
                    }
                };
                this.$sapi.callPort(option);
            },
            scanCheck() {
                this.$router.push({path: 'scanCheck'});
            },
            onLoadCarOrderOutStockConfirm(value) {
                var me = this;
                me.loadCarOrderDetailId = value.loadCarOrderDetailId;
                me.outStockCode = value.outStockCode;
                var option = {
                    portName: 'fr.order.out.LoadCarOrder.outStockInfo',
                    data: {'loadCarOrderDetailId': me.loadCarOrderDetailId},
                    needLoading: true,
                    successCallback: function (data) {
                        me.info = data.data.info;
                        me.dataList = data.data.materialDicList;
                    }
                };
                this.$sapi.callPort(option);
            },
        }
    }
</script>

<style scoped>
    .item {
        display: flex;
        border-radius: 5px;
        flex-direction: row;
        box-shadow: 0 0 2px #ddd;
        align-items: center;
        padding: 10px;
        margin: 2px;
    }

    .item + .item {
        margin-top: 10px;
    }

    .item > .content {
        flex: 1;
    }

    .item .time {
        color: #666;
    }
</style>